<template>
  <section class="slothguard">
    <h1 class="text-white">SlothGuard</h1>
    <p class="description">
      Title fraud can cost you the entire value of your home. This happens when someone fraudulently
      transfers your property into their name using false documents & HM land registry. SlothGuard
      will notify you when someone either lists your property online or makes official searches with
      HM Land Registry against it 🔒
    </p>

    <!-- <form class="miniform" @submit.prevent>
      <div class="text-center mb-2">
        <img
          :src="`${urlBucket}/dashboard/sloth-guard-header-miniform.png`"
          class="img-fluid"
          width="270px"
          alt=""
        />
      </div>

      <div class="mb-2 question">
        <p style="font-size: 15px" class="text-left font-weight-bolder">
          Which property would you like to protect?
        </p>
        <vSelect
          :value="''"
          class="w-100 select"
          label="title"
          :clearable="false"
          :options="[1, 2, 3, 4, 5]"
          :placeholder="'placeholder'"
          :multiple="false"
          @input="(e) => {}"
        >
          <template slot="option" slot-scope="option">
            {{ option.title ? option.title : option }}
          </template>
        </vSelect>
        <p class="mt-1 mb-0 text-left question-error animate__animated animate__shakeX">* Error</p>
      </div>


      <div class="mb-2 question">
        <p style="font-size: 15px" class="text-left font-weight-bolder">What is your full name?</p>

        <input
          type="text"
          :placeholder="'Placeholder'"
          class="w-100 p-1"
          :value="''"
          @input="(e) => {}"
        />
        <p class="mt-1 mb-0 text-left question-error animate__animated animate__shakeX">* Error</p>
      </div>


      <div class="mb-2 question">
        <p style="font-size: 15px" class="text-left font-weight-bolder">
          Which email address shall we send alerts to?
        </p>

        <input
          type="text"
          :placeholder="'Placeholder'"
          class="w-100 p-1"
          :value="''"
          @input="(e) => {}"
        />
        <p class="mt-1 mb-0 text-left question-error animate__animated animate__shakeX">* Error</p>
      </div>

      <div class="label d-flex align-items-center mt-1 mb-2 px-2">
        <img :src="`${urlBucket}/dashboard/check-y.png`" width="30px" alt="" class="mr-1" />
        <p class="ms-1 mb-0">Excellent, you can select XXYYZZ</p>
      </div>

      <button class="button">
        <p class="mb-0 font-weight-bolder">Submit</p>
      </button>
    </form> -->

    <form class="miniform mt-3" @submit.prevent>
      <div class="text-center mb-2">
        <img
          :src="`${urlBucket}/dashboard/sloth-guard-header-miniform.png`"
          class="img-fluid"
          width="335px"
          alt=""
        />
      </div>

      <div class="d-flex justify-content-center" v-if="protectionActive">
        <p class="mb-0">Protection Status: <span class="font-weight-bolder">Active</span></p>

        <img
          :src="`${urlBucket}/dashboard/circle-active.png`"
          width="20px"
          style="margin-left: 5px"
          alt=""
        />
      </div>

      <div class="card mt-2">
        <div class="card-body">
          <div class="container-icon-shield w-100 text-center">
            <div class="icon">
              <img :src="`${urlBucket}/dashboard/sloth-shield.png`" width="35px" />
            </div>
          </div>

          <div style="text-align: start">
            <p class="font-weight-bolder">Property <span class=""></span></p>
            <p class="mb-0">{{ moveDetails.moveToAddress }}</p>
            <p class="mb-0">{{ moveDetails.city }}</p>
            <p class="mb-0">{{ moveDetails.moveToPostCode }}</p>
          </div>
        </div>
      </div>

      <p class="mb-0 font-weight-bolder">SlothGuardian</p>
      <p class="mb-2">{{ userInfo() }}</p>

      <div v-if="protectionActive">
        <p class="mb-0 font-weight-bolder">Email Alerts will be sent to</p>
        <p class="mb-2">{{ email }}</p>
      </div>

      <div class="mb-2 question" v-if="!protectionActive">
        <p class="text-left font-weight-bolder" style="margin-bottom: 6px">
          Which email address shall we send alerts to?
        </p>

        <input type="text" :placeholder="'Email'" class="w-100 p-1" v-model="email" />
        <p
          class="mt-1 mb-0 text-left question-error animate__animated animate__shakeX"
          v-if="emailInvalid"
        >
          * This email is invalid
        </p>
      </div>

      <button class="button mt-3" @click="submit()" v-if="!protectionActive">
        <p class="mb-0 font-weight-bolder" style="font-size: 18px">Submit</p>
      </button>
    </form>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axiosCustom from "@axios";
import Vue from "vue";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Swal from "sweetalert2";

export default {
  name: "SlothGuard",
  components: {
    vSelect,
  },
  data() {
    return {
      protectionActive: false,
      emailInvalid: false,
      moveDetails: {},
      email: JSON.parse(localStorage.getItem("userData")).email,
    };
  },
  mounted() {
    this.moveDetails = this.lastMove.moveDetails;

    let protectionActive = Object.values(this.lastMove.admin["slothGuard"]).find(
      (string) => string
    );

    if (protectionActive) this.protectionActive = true;
  },
  methods: {
    userInfo() {
      return this.user["firstName"] + " " + this.user["lastName"];
    },
    submit() {
      let preferredEmail = this.user["email"];
      const regexMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      console.log(preferredEmail);

      if (regexMail.test(preferredEmail)) {
        this.emailInvalid = false;

        axiosCustom
          .post(`/slothguard/subscribeForAlerts`, {
            email: this.user.email,
            preferredEmail,
          })
          .then((result) => {
            console.log(result.data);
            this.protectionActive = result.data;

            //refreshing LAST MOVE
            axiosCustom.get(`api/user/getDetails?email=${this.user.email}`).then(({ data }) => {
              this.$store.commit("app/setField", ["lastMove", data.moves[data.moves.length - 1]]);
            });
            //refreshing LAST MOVE

            if (!this.protectionActive) {
              displayError();
            }
          })
          .catch((err) => displayError())
          .finally(() => {});

        function displayError() {
          Swal.fire({
            icon: "error",
            title: "Oops,...",
            text: "something is not right. Please contact hello@slothmove.com and we will take care of it",
            customClass: "slotguard-modal",
          });
        }
      } else {
        this.emailInvalid = true;
      }
    },
  },
  computed: {
    ...mapState("app", ["user", "urlBucket", "currentMove", "logoPartner", "lastMove"]),
    ...mapGetters("app", ["isAPartner", "colorPartner"]),
  },
  watch: {},
};
</script>

<style lang="scss">
.slothguard {
  margin-left: 12em;

  @media screen and (max-width: 900px) {
    margin-left: 0;
  }

  h1 {
    font-weight: bold;
  }
  p {
    color: white;
    font-size: 16px;
  }

  .miniform,
  .description {
    max-width: 550px;
    text-align: justify;
  }

  .description {
    line-height: 1.7;
  }

  .miniform {
    border: 5px solid #ec069c;
    border-radius: 30px;
    background-color: #212534;
    padding: 27px 40px;
    padding-top: 15px;

    p {
      color: white;
      font-size: 16px;
    }

    .card {
      background-color: transparent !important;
      border: 2px solid #21c286;
      max-width: 255px;

      .container-icon-shield {
        position: absolute;
        left: 0;
        top: -20px;
        display: flex;
        justify-content: center;

        .icon {
          border-radius: 15px;
        }
      }
    }

    input[type="text"] {
      outline: none;
      border-radius: 0.375rem;
      border: none;
      color: black;
      font-size: 16px;
    }

    .question-error {
      font-size: 14px;
      color: #c64a45;
    }

    .select {
      font-size: 14px;
      .vs__selected {
        color: black;
      }

      .vs__dropdown-toggle {
        padding: 0.6rem !important;
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
        border-radius: 0.375rem;
        color: black;
        background-color: white;
      }

      .vs__dropdown-menu {
        color: black !important;
      }
    }

    .label {
      background-color: rgba(57, 61, 74, 0.62);
      border-radius: 10px;
      padding: 6px;
    }

    button {
      all: unset;
      box-sizing: border-box;
      width: 100%;
      display: block;
      padding: 15px;
      color: white;
      text-align: center;
      letter-spacing: 1.5px;
      font-weight: bold;
      cursor: pointer;
      border-radius: 13px;
      font-size: 16px;
    }

    .button {
      background-color: #ec0677;
      box-shadow: 0 5px 0 #7d0741;
    }

    .button:active {
      box-shadow: none;
      transform: translateY(5px);
    }
  }
}
</style>
